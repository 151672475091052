<template>
  <div
    class="empty:hidden"
    :class="[style.rteParser, { [style.rteParserPrimaryLight]: lightText }]"
  >
    <RichTextVueRenderer
      :document="richText"
      :node-renderers="customNodeRenderers"
    />
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./contentful-rich-text-vue-renderer.d.ts" />

import { useRequestURL } from '#imports'
import { h, useCssModule } from 'vue'

import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'
import { tw } from '@backmarket/utils/string/tw'
import {
  BLOCKS,
  type Heading1,
  type Heading2,
  type Heading3,
  type Hyperlink,
  INLINES,
  type OrderedList,
  type UnorderedList,
} from '@contentful/rich-text-types'
// eslint-disable-next-line import/order
import { RevLink } from '@ds/components/Link'

import RichTextVueRenderer, {
  type NextRendererFn,
  type NodeRendererMap,
} from 'contentful-rich-text-vue-renderer'

import { transformHref } from '../../helpers/link/transformHref'

withDefaults(defineProps<RichTextProps>(), {
  lightText: false,
})
const currentUrl = useRequestURL()
const style = useCssModule()

const customNodeRenderers: NodeRendererMap<{
  [INLINES.HYPERLINK]: Hyperlink
  [BLOCKS.HEADING_1]: Heading1
  [BLOCKS.HEADING_2]: Heading2
  [BLOCKS.HEADING_3]: Heading3
  [BLOCKS.UL_LIST]: UnorderedList
  [BLOCKS.OL_LIST]: OrderedList
}> = {
  [INLINES.HYPERLINK]: (node: Hyperlink, key, next) => {
    const { uri } = node.data

    const { href: relativeHref } = transformHref({ href: uri, currentUrl })

    return h(RevLink, { to: relativeHref }, () => next(node.content, key, next))
  },
  [BLOCKS.HEADING_1]: (node: Heading1, key: string, next: NextRendererFn) => {
    return h('h1', { key, class: tw`heading-1` }, next(node.content, key, next))
  },
  [BLOCKS.HEADING_2]: (node: Heading2, key: string, next: NextRendererFn) => {
    return h('h2', { key, class: tw`heading-2` }, next(node.content, key, next))
  },

  [BLOCKS.HEADING_3]: (node: Heading3, key: string, next: NextRendererFn) => {
    return h('h3', { key, class: tw`heading-3` }, next(node.content, key, next))
  },

  [BLOCKS.UL_LIST]: (
    node: UnorderedList,
    key: string,
    next: NextRendererFn,
  ) => {
    return h(
      'ul',
      { key, class: tw`list-disc px-20` },
      next(node.content, key, next),
    )
  },
  [BLOCKS.OL_LIST]: (node: OrderedList, key: string, next: NextRendererFn) => {
    return h(
      'ol',
      { key, class: tw`list-decimal px-20` },
      next(node.content, key, next),
    )
  },
}
</script>

<style module>
.rteParser {
  @apply body-1 text-static-default-mid flex flex-col mx-auto w-full gap-24 items-center;
}
@media (min-width: theme('screens.md')) {
  .rteParser {
    max-width: 112rem;
  }
}

/* don't display the last <p> tags with empty content */
.rteParser p:nth-last-of-type(1):empty {
  display: none;
}

.rteParser > :not(table) {
  width: 100%;
  max-width: 736px;
}
@media (min-width: theme('screens.lg')) {
  .rteParser > :not(table) {
    width: 736px;
    max-width: 100%;
  }
}

.rteParser > *:last-child {
  margin-bottom: 0;
}

.rteParser blockquote,
.rteParser blockquote > * {
  @apply text-static-default-mid heading-1-italic text-center my-56;
}
@media (min-width: theme('screens.md')) {
  .rteParser blockquote,
  .rteParser blockquote > * {
    @apply mt-72 mb-72;
  }
}

.rteParser table {
  @apply bg-surface-default-low shadow-short pt-24 pb-32 py-16 px-24 mb-56 block overflow-x-auto;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100vw;
  border-radius: 0;
}
@media (min-width: theme('screens.md')) {
  .rteParser table {
    @apply rounded-lg mb-72 p-32;
    max-width: 1120px;
    width: auto;
  }
}

.rteParser table tr {
  @apply border-b-1 border-b-static-default-low;
  min-width: 160px;
  max-width: 100%;
}
.rteParser table tr:last-child {
  border-bottom: 0;
}

.rteParser table tbody {
  border-color: inherit;
  width: 100%;
}

.rteParser table p {
  margin-bottom: 0;
}

.rteParser table th,
.rteParser table td {
  @apply py-12 px-8 text-left;
  min-width: 160px;
  max-width: 100%;
}
.rteParser table tr:first-child th {
  padding-top: 0;
}
.rteParser table tr:last-child td {
  padding-bottom: 0;
}
@media (min-width: theme('screens.md')) {
  .rteParser table th,
  .rteParser table td {
    @apply p-16;
  }
}

.rteParserPrimaryLight {
  @apply text-static-default-low;
}
</style>
