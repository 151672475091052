<template>
  <div
    class="empty:hidden"
    :class="[$style.rteParser, { [$style.rteParserPrimaryLight]: lightText }]"
  >
    <UnstyledRichText :rich-text="richText" />
  </div>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'

import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'

import UnstyledRichText from '../../shared-components/UnstyledRichText/UnstyledRichText.vue'

useCssModule()

withDefaults(defineProps<RichTextProps>(), {
  lightText: false,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])
</script>

<style module>
.rteParser {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @apply body-1 text-static-default-mid;
}
@media (min-width: theme('screens.md')) {
  .rteParser {
    max-width: 112rem;
  }
}

/* don't display the last <p> tags with empty content */
.rteParser p:nth-last-of-type(1):empty {
  display: none;
}

.rteParser div {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.rteParser div > :not(table) {
  width: 100%;
  max-width: 736px;
}
@media (min-width: theme('screens.lg')) {
  .rteParser div > :not(table) {
    width: 736px;
    max-width: 100%;
  }
}

.rteParser div > *:last-child {
  margin-bottom: 0;
}

.rteParser h2 {
  @apply heading-2;
}

.rteParser h3 {
  @apply heading-3;
}

.rteParser blockquote,
.rteParser blockquote > * {
  @apply text-static-default-mid heading-1-italic text-center my-56;
}
@media (min-width: theme('screens.md')) {
  .rteParser blockquote,
  .rteParser blockquote > * {
    @apply mt-72 mb-72;
  }
}

.rteParser ul {
  list-style-type: disc;
}

.rteParser ul,
.rteParser ol {
  @apply px-20;
}

.rteParser ol li {
  list-style: decimal;
}

.rteParser a {
  @apply text-action-default-hi font-weight-body-1-bold;
  text-decoration: underline;
}

.rteParser a:hover,
.rteParser a:focus {
  @apply text-action-default-hi-hover;
}

.rteParser table {
  @apply bg-surface-default-low shadow-short pt-24 pb-32 py-16 px-24 mb-56 block overflow-x-auto;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100vw;
  border-radius: 0;
}
@media (min-width: theme('screens.md')) {
  .rteParser table {
    @apply rounded-lg mb-72 p-32;
    max-width: 1120px;
    width: auto;
  }
}

.rteParser table tr {
  @apply border-b-1 border-b-static-default-low;
  min-width: 160px;
  max-width: 100%;
}
.rteParser table tr:last-child {
  border-bottom: 0;
}

.rteParser table tbody {
  border-color: inherit;
  width: 100%;
}

.rteParser table p {
  margin-bottom: 0;
}

.rteParser table th,
.rteParser table td {
  @apply py-12 px-8 text-left;
  min-width: 160px;
  max-width: 100%;
}
.rteParser table tr:first-child th {
  padding-top: 0;
}
.rteParser table tr:last-child td {
  padding-bottom: 0;
}
@media (min-width: theme('screens.md')) {
  .rteParser table th,
  .rteParser table td {
    @apply p-16;
  }
}

.rteParserPrimaryLight {
  @apply text-static-default-low;
}
</style>
