<template>
  <RichTextVueParser v-if="useNewRichText" v-bind="props" />

  <RichTextHtmlParser v-else v-bind="props" />
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'

import RichTextHtmlParser from './RichTextHtmlParser.vue'
import RichTextVueParser from './RichTextVueParser.vue'

const { FF_CMS_NEW_RICH_TEXT_ENABLED = false } = useRuntimeConfig().public

const useNewRichText = FF_CMS_NEW_RICH_TEXT_ENABLED

const props = withDefaults(defineProps<RichTextProps>(), {
  lightText: false,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])
</script>
